import type { VueIslandConfig } from "@/shared/vueIslands";

import { initVueIslands } from "@/shared/vueIslands";

const components: VueIslandConfig = {
  brochureForm: () =>
    import("@/platform/components/brochure-form/BrochureForm.vue"),
  productSearch: () =>
    import("@/vue/apps/client/components/CrossSells/ProductSearch.vue"),
  explanationAnimation: () =>
    import(
      "@/platform/components/explanation/explanation-animation/ExplanationAnimation.vue"
    ),
  reviewForm: () => import("@/platform/components/review-form/ReviewForm.vue"),
  sBlogList: () => import("@/platform/components/blog/SBlogList.vue"),
  sAffiliateForm: () =>
    import("@/affiliates/components/registration-form/SAffiliateForm.vue"),
  sReviews: () => import("@/platform/components/reviews/SReviews.vue"),
};

const pages: VueIslandConfig = {
  companyWallOverview: () =>
    import("@/platform/pages/company-wall/CompanyWall.vue"),
  searchPage: () => import("@/platform/pages/search/SearchPage.vue"),
};

initVueIslands({ ...components, ...pages });
